import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import MessageSnackBar from './Components/MessageSnackBar';

import FlexLogo from './flexe_Logo.png';
import WALogo from './WA_logo.png';

import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import EdonLogo from './edonLogoWhite.png'
import BarChart from './Components/Charts/BarChart.js'
import LineChart from './Components/Charts/LineChart.js'
import PieChart from './Components/PieChart.js'
import MetricsDefinitions from './img/MetricsDefinitions.png'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import HeaderMenus from './Components/HeaderMenus.js';
import Unauthorized from './Components/Unauthorized.js'

import AdminMetrics from './Components/AdminMetrics.js' 
import DigitalClock from './Components/DigitalClock.js';
import AnalogClock from './Components/AnalogClock.js';



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Edon Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}




export default function PerformanceMetrics(props) {

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION



  const userData = useContext(UserContext)

  const pageTitle = 'Performance Metrics'
  const [open, setOpen] = React.useState(true);

  var data = [{'name': 'Aug 2022', 'value': '2660'}, {'name': 'Sep 2022', 'value': '2965'}, {'name': 'Nov 2022', 'value': '1321'}, {'name': 'Dec 2022', 'value': '796'}, {'name': 'Jan 2023', 'value': '673'}]
  var range = ['2660', '2965', '1321', '796', '673']

  const lineData = [
    {
      "name": "Jun 2022",
      "value": .75
    },
    {
      "name": "Jul 2022",
      "value": .78
    },
    {
      "name": "Aug 2022",
      "value": .72
    },
    {
      "name": "Sep 2022",
      "value": .82
    },
    {
      "name": "Nov 2022",
      "value": .91
    },
    {
      "name": "Dec 2022",
      "value": .94
    },
    {
      "name": "Jan 2023",
      "value": .95
    }
  ]
  
  const piedata01 = [
    {
      "name": "Success",
      "value": 75,
    },
    {
      "name": "Execution Failure",
      "value": 15,
    },
    {
      "name": "Planning Failure",
      "value": 10,
    }
  ];
  const colors01 = ['#548235', '#C55A11', '#2E75B6']
  const piedata02 = [
    {
      "name": "Utilization",
      "value": 40,
    },
    {
      "name": "Replenishment",
      "value": 20, 
    },
    {
      "name": "Plan Replenishment",
      "value": 20,
    },
    {
      "name": "Plan Inventory",
      "value": 8,
    },
    {
      "name": "Plan Location",
      "value": 12,
    }
  ];
  const colors02 = ['#C55A11', '#ED7D30', '#2F5597', '#9DC3E6', '#2E75B6']



  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          {/* Content*/}
          {/* Content*/}
          {/* Content*/}
          <img src={EdonLogo} width={200} alt="Logo" />

          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
            >

            {/* Header */}
            {userData.membershipCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {userData.membershipCode === "0x4ee11bfe" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            }

          <Grid
              container
              // overflow="auto"
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  <Typography align="center" variant={"h4"}>
                    {userData.userCompany}
                  </Typography>
                  <Typography align="center" variant={"h4"}>
                    {pageTitle}
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {userData.email}
                  </Typography>



          {/* {!userData.services.includes("PM") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  } */}
          

          {/* Clock */}
          {/* <Grid container paddingTop = {2} pb={0} border={0} spacing={0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Box justifyContent="center" alignItems="center" sx={{ p: 0,  align: "center", border: 0 }}>
            <Grid item spacing={0} lg={12} sx={{ alignContent: "center", border: 0 }}>
              <AnalogClock />
            </Grid>

            <Grid item spacing={0} lg={12} sx={{ alignContent: "center", border: 0 }}>
              <DigitalClock />
            </Grid>
            </Box>
          </Grid> */}
          {/* End Clock */}

          <Grid container spacing={3} sx={{ border: 0, p: 2 }}>

            <Grid item xs={12} sm={12} md={12} sx={{border:0, p:5}}>  
              <AdminMetrics />
            </Grid>

              
          {false &&
          <>
          {/* Service Level Parts Chart */}
          <Grid item  xs={6} sm={6} md={6} sx={{border:1}}>
          <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom center component="div">Service Level with Parts </Typography>
          </Box>
            <PieChart data={piedata01}  colors={colors01} range={range} />
          </Grid>

          {/* Service Level Parts Chart */}
          <Grid item  xs={6} sm={6} md={6} sx={{border:1}}>
            <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom>Service Failures with Parts </Typography>
            </Box>
            <PieChart data={piedata02}  colors={colors02}  range={range} />
          </Grid>

          {/* Service Level Parts Line Chart */}
          <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
            <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom>Service Failures with Parts </Typography>
            </Box>
            <LineChart data={lineData} range={range} />
          </Grid>

          {/* Service Level Parts Bar Chart */}
          <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
            <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom>Service Orders</Typography>
            </Box>
            <BarChart data={data} range={range} />
          </Grid>

          {/* Service Level Parts Card */}
          <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
          <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '10%',
                      maxHeight: "100%"
                    }}
                    image={MetricsDefinitions}
                  />

              </Card>
          </Grid>
          </>
          }


          </Grid>
          </Paper>

          </Grid>

          <Box sx={{justifyContent:'center', border:0}}>
                    <Copyright sx={{ p: 2, align: "center" }} />
            </Box>

          </Grid>
        </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}