import { color } from "@mui/system";

// dataGridStyles.js
export const dataGridStyles = {
    root: {
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            color: 'white',
        },

        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: '1px solid white',
        },
        '& .MuiDataGrid-cell': {
            borderRight: '1px solid white',
            color: 'white',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 2,
        },
    }
  };