import * as React from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { useContext } from 'react'; 
import { UserContext } from '../context/UserContext.js';

import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
// import Input from '@mui/material/Input'
import MessageSnackBar from './MessageSnackBar.js';

const Input = styled('input')({
  display: 'none',
});

export default function DragDropTwo(props) {
  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};


  const [selectedFile, setSelectedFile ] = React.useState();
  const [selectedFileName, setSelectedFileName ] = React.useState(true);
  const [viewSelectFileButton, setViewSelectFileButton ] = React.useState(true);
  const [viewLoadingSpinner, setviewLoadingSpinner ] = React.useState(false);

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  const handleCapture = (event) => {

    setSelectedFile(event.target.files[0])
    setSelectedFileName(event.target.files[0].name)
    setViewSelectFileButton(false);

  }
  //SECTION Axios Upload a Demand File Post
  const handleUploadFile = () => {
    const userid = userData.email
    setviewLoadingSpinner(true);

    
    const postHeaders = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `${headers.Authorization}`
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    var postConfig = {
      method: 'post',
      url: `/Shipments/bulkfileuploadASN`,
      data : formData,
      headers: postHeaders
    };
    axios(postConfig)
    .then(function (response) {
      //! put in a message bar
      setviewLoadingSpinner(false);
      // props.handleUploadData(message, response);
      setViewSelectFileButton(true); 
      setMessageBarMessage(response.data.message);
      setMessageBarOpen(true);
      setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
      if (response.data.code){
        // console.log(Object.values(response.data)[1])
        alert(Object.values(response.data)[1])
        return
      }
      // props.handleUploadData(response);
    })
    .catch(function (error) {
      setviewLoadingSpinner(false);

      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status !== 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }

    });
  }
  //!SECTION


  return (

    <Grid container marginTop={5} sx={{justifyContent:'center', alignItems:'center', alignContent:'center', p:1, border:1, borderStyle: 'dotted', borderRadius: '5px'  }}>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ justifyContent: "center", alignItems:'center', alignContent:'center', border: 0, marginTop: 5, marginBottom: 2 }}
          >
            <Typography variant="h4" align="center" >
              ASN File Upload
            </Typography>
          </Grid>
    {viewSelectFileButton &&
        <Grid container  sx={{justifyContent:'center', border:0, p:5, borderStyle: 'dotted', borderRadius: '5px'  }} >
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="label">
            <Input accept=".csv, .xlsx" onChange={handleCapture} id="contained-button-file" hidden type="file" />
              Select Your File to Upload
            </Button>
          </label>
        </Grid>
        }

      {!viewSelectFileButton &&
      <Grid container sx={{justifyContent:'center', border:0, p:0, borderStyle: 'dotted', borderRadius: '5px'  }}>
        <Button onClick={handleUploadFile} variant="contained" component="label">
          Upload
          {viewLoadingSpinner &&
          <CircularProgress sx={{margin:1}} color="inherit" size ={20}/>
            }
        </Button>
        <Grid container sx={{justifyContent:'center', border:0, p:0, borderStyle: 'dotted', borderRadius: '5px'  }}>
      <Typography>
            {selectedFileName}
      </Typography>
      </Grid>
      </Grid>
      }

    </Grid>
  );
}