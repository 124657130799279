import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { useHistory } from 'react-router-dom';
// import axios from 'axios';

import {useAuth0 } from "@auth0/auth0-react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from '../listItems';
import Button from '@mui/material/Button';
import EdonLogo from '../edonLogoWhite.png';
import MessageBell from './MessageBell';
import DigitalClock from './DigitalClock.js';
import AnalogClock from './AnalogClock.js';

const REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;


// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



const drawerWidth = 260;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default function HeaderMenus(props) {
  // Auth0 login/logout
  const { loginWithRedirect } = useAuth0();
  const { user } = useAuth0();
  const { logout } = useAuth0();
  const history = useHistory();

  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
  
  
  // Login handler
  const handleLogin = async () => {
    
    try {
      await loginWithRedirect({
        appState: {
          returnTo: "/",
        },
        authorizationParams: {
          prompt: "login",
          redirect_uri: REACT_APP_REDIRECT_URL
        },
      });
    } catch (err) {
      console.error("Login attempt failed", err);
      // setError("Login failed. Please try again.");
    } finally {
      // setLoading(false);
      console.log("Login attempted");
    }
  };

  // Logout handler
  const handleLogout = () => {
    // console.log("Logging Out", window.location.origin);
    // console.log("REDIRECT and HeaderMenus user: ", REACT_APP_REDIRECT_URL, user);
    localStorage.clear();
    sessionStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  
  // Drawer toggle
  const toggleDrawer = () => {
    handleOpenHeaderDrawer();
  };

  const [openHeaderDrawer, setOpenHeaderDrawer] = React.useState(true);
  const handleOpenHeaderDrawer = () => {
    setOpenHeaderDrawer(!openHeaderDrawer);
  };


  const handlePasswordChange = async () => {


    history.push('/PasswordSet');
    // console.log("handlePasswordChange");
    const domain = process.env.REACT_APP_AUTH0_DOMAIN; // e.g., yourdomain.auth0.com
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    // const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL; // Your callback URL
    const redirectUri = "http://localhost:3000/"; // Your callback URL
    const email = userData.email; 
    // const email = user.email; // User's email
    // console.log("email: ", email);
    // console.log("domain: ", domain);
    // console.log("clientId: ", clientId);
    // console.log("redirectUri: ", redirectUri);
    // console.log("user: ", userData);

    // await loginWithRedirect({
    //   appState: {
    //     returnTo: "/",
    //   },
    //   authorizationParams: {
    //     client_id: clientId,
    //     redirect_uri: redirectUri,
    //     scope: 'openid profile email',
    //     state: 'STATE',
    //     screen_hint: 'change_password',
    //     login_hint: email,
    //     audience: 'https://edon-connect-backend-2-b3d2e6a4a550.herokuapp.com',
    //     response_type: 'code',
    //     response_mode: 'query',
    //     code_challenge_method: 'S256',
    //   },
    // });
  
    // const changePasswordUrl = `https://${domain}/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=openid%20profile%20email&state=STATE&screen_hint=change_password&email=${email}`;
    // console.log("changePasswordUrl: ", changePasswordUrl);
    // window.location.href = changePasswordUrl;
  };


  

  // const handlePasswordChange = () => {
  //   const domain = REACT_APP_AUTH0_DOMAIN; // e.g., yourdomain.auth0.com
  //   const clientId = REACT_APP_AUTH0_CLIENT_ID;
  //   const redirectUrl = `https://${domain}/u/reset-password/?email=${user.email}&client_id=${clientId}`;
  //   window.location.href = redirectUrl;
  // };
  

  //Clocks seem to be causing a problem


  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        
        <AppBar position="absolute" open={openHeaderDrawer}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: 'inherit',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(openHeaderDrawer && { display: 'none' }),
              }}
            >
            {user && <MenuIcon />}

            </IconButton>
            {!openHeaderDrawer&&
            <>
            <Divider orientation="vertical" flexItem />
              <img align="center" src={EdonLogo} width={195} alt="Logo" />
            <Divider orientation="vertical" flexItem />
            </>
            }
            <Typography
              paddingLeft={3}
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {props.pageTitle}
            </Typography>

            {userData &&
            <Button 
            variant="contained"
            onClick={handleLogout} 
            color="secondary"
            >
              Sign Out
            </Button>
            }
            {!userData &&
            <Button
            variant="contained"
            onClick={handleLogin}
            >
              Sign On
            </Button>
          }
          <MessageBell />
          </Toolbar>
        </AppBar>


        {/* Add a indicator */}
        {userData && 
        <Drawer variant="permanent" open={openHeaderDrawer}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <img src={EdonLogo} width={200} alt="Logo" />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />

          {userData.sub.slice(0, 4) === "auth" &&
          <Button onClick={handlePasswordChange}>Change Password</Button>
          }
          <Divider />
          {/* <Grid container spacing={2} direction="column" justifyContent="center" padding={2} alignItems="center"> 
            <Grid item  >
            <AnalogClock />
            </Grid>
          </Grid> */}
          {/* <DigitalClock />
          <Divider /> */}
          <List>{mainListItems}</List>
          <Divider />
          {/* <AnalogClock /> */}
        </Drawer>
        }
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar /> 
        </Box>
      </Box>


    </ThemeProvider>
  );
}