import * as React from 'react';
import { useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import axios from 'axios';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material//MenuItem';

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './MessageSnackBar';
import { UserContext } from '../context/UserContext';




export default function MobileUser(props) {

  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};


  //! Local SnackBar Messaging
  // eslint-disable-next-line 
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  //SECTION Message Bar Controls
  const [ messageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  //&Account DropDown
  //&Account DropDown
  //&Account DropDown
  //! Data and Select for Account Dropdown
  const [selectedAccount, setSelectedAccount] = React.useState('');
  const [selectedMarket, setSelectedMarket] = React.useState('');
  //! accountList comes from Dashboard
  const handleAccountSelect = (event) => {
    setSelectedAccount(event.target.value);
    props.getMarketList(event.target.value);

  };
  const handleMarketSelect = (event) => {
    setSelectedMarket(event.target.value);
  };



   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);


    const [ validFields, setValidFields ] = React.useState(
      {
        "warehouseLocationName": false,
        "warehouseNumber": false,
        "warehouseAddress": false,
        "warehouseCity": false,
        "warehouseState": false,
        "warehouseZip": false,
        "warehouseType": false,
        "warehouseDescription": false,
     });

    const [ helperText, setHelperText ] = React.useState(
      {
        "warehouseLocationName":"Warehouse Location Name",
        "warehouseNumber":"Warehouse Number",
        "warehouseAddress":"Warehouse Address",
        "warehouseCity":"Warehouse City",
        "warehouseState":"Warehouse State",
        "warehouseZip":"Warehouse Zip",
        "warehouseType":"Warehouse Type",
        "warehouseDescription":"Warehouse Description",
       });

    const [ validWarehouseName, setValidWarehouseName ] = React.useState(false);
    const [ validWarehouseNumber, setValidWarehouseNumber ] = React.useState(false);
    const [ validWarehouseAddress, setValidWarehouseAddress ] = React.useState(false);
    const [ validWarehouseCity, setValidWarehouseCity ] = React.useState(false);
    const [ validWarehouseState, setValidWarehouseState ] = React.useState(false);
    const [ validWarehouseZip, setValidWarehouseZip ] = React.useState(false);
    const [ setValidWarehouseType ] = React.useState(false);
    const [ setValidWarehouseDescription ] = React.useState(false);

    React.useEffect(() => {
        if (validWarehouseName &&
            validWarehouseNumber &&
            validWarehouseAddress &&
            validWarehouseCity &&
            validWarehouseState &&
            validWarehouseZip
          ) {
          setButtonDisable(false);
        }else{
          setButtonDisable(true);
        }
      },[ validWarehouseName, validWarehouseNumber, validWarehouseAddress, validWarehouseCity, validWarehouseState, validWarehouseZip]
      )
 

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'warehouseLocationName': 
          var warehouseName = e.target.value;
          // Regular Expression for a no special characters
          var regex = /^[a-zA-Z0-9_ ]*$/;

          if (warehouseName.length > 4 && regex.test(warehouseName)){
            setValidFields(prevState => ({
                ...prevState,
                "warehouseLocationName": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            warehouseLocationName: "Warehouse Location Name",
            }));
            setValidWarehouseName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "warehouseLocationName": true}));

              setHelperText(prevState => ({
                ...prevState,
                warehouseLocationName: "Name needs at least six characters. With no special characters",
                }));
              setValidWarehouseName(false);
          }
          break;

        case 'warehouseNumber': 
          var warehouseNumber = e.target.value;
          // eslint-disable-next-line 
          if (warehouseNumber.length > 5){
            setValidFields(prevState => ({
                ...prevState,
                "warehouseNumber": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            warehouseNumber: "Warehouse Number",
            }));
            setValidWarehouseNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "warehouseLocationNumber": true}));

              setHelperText(prevState => ({
                ...prevState,
                warehouseLocationNumber: "Warehouse Number needs at least six characters",
                }));
              setValidWarehouseNumber(false);
          }
          break;

        case 'warehouseAddress':
          var address = e.target.value;
          // eslint-disable-next-line 
          if (address.length > 5){
            setValidFields(prevState => ({
                ...prevState,
                "warehouseAddress": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            warehouseAddress: "Warehouse Address",
            }));
            setValidWarehouseAddress(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "warehouseAddress": true}));

              setHelperText(prevState => ({
                ...prevState,
                warehouseAddress: "Address needs at least six characters",
                }));
              setValidWarehouseAddress(false);
          }
          break;
        case 'warehouseCity':           
            var city = e.target.value;
            // eslint-disable-next-line
            if (city.length > 4){
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseCity": false, 
                    }));
                setHelperText(prevState => ({
                ...prevState,
                warehouseCity: "Warehouse City",
                }));
                setValidWarehouseCity(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseCity": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    warehouseCity: "City needs at least five characters",
                    }));
                setValidWarehouseCity(false);
            }
            break;
        case 'warehouseState':
            var state = e.target.value;
            // eslint-disable-next-line
            if (state.length > 1){
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseState": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                warehouseState: "Warehouse State",
                }));
                setValidWarehouseState(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseState": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    warehouseState: "State needs at least two characters",
                    }));
                setValidWarehouseState(false);
            }
            break;
        case 'warehouseZip':
            var zip = e.target.value;
            // eslint-disable-next-line
            if (zip.length > 4){
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseZip": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                warehouseZip: "Warehouse Zip",
                }));
                setValidWarehouseZip(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseZip": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    warehouseZip: "Zip needs at least five characters",
                    }));
                setValidWarehouseZip(false);
            }
            break;
        case 'warehouseType':
            var type = e.target.value;
            
            if (type.length > -1){
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseType": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                warehouseType: "Warehouse Type",
                }));
                setValidWarehouseType(true);
              // put in a check for invalid characters
            } else {
                setValidFields(prevState => ({
                    ...prevState,

                    "warehouseType": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    warehouseType: "Type needs at least one character",
                    }));
                setValidWarehouseType(false);
            }
            break;
        case 'warehouseDescription':
            var description = e.target.value;
            // eslint-disable-next-line
            if (description.length > -1){
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseDescription": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                warehouseDescription: "Warehouse Description",
                }));
                setValidWarehouseDescription(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "warehouseDescription": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    warehouseDescription: "Description needs at least ten characters",
                    }));
                setValidWarehouseDescription(false);
            }
            break;

        default:
          break;
      }
    }


    //!SECTION
    


    //SECTION Axios Create User
  const handleCreateWarehouse = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    var assignAccount = formData.get("assignAccount");
    var warehouseName = formData.get("warehouseLocationName");
    var warehouseNumber = formData.get("warehouseNumber");
    var warehouseAddress = formData.get("warehouseAddress");
    var warehouseCity = formData.get("warehouseCity");
    var warehouseState = formData.get("warehouseState");
    var warehouseZip = formData.get("warehouseZip");
    var warehouseType = formData.get("warehouseType");
    var warehouseDescription = formData.get("warehouseDescription");
    if (!warehouseType){
        warehouseType = "None";
    };
    if (!warehouseDescription){
        warehouseDescription = "None";  
    };
    if (assignAccount === "" || assignAccount === null || assignAccount === undefined){
      assignAccount = userData.accountCode;
    }
    // const headers = props.headers;
    //make sure warehouseNumber is a string
    

    var data = {
        accountCode: assignAccount, 
        warehouseName: warehouseName,
        warehouseNumber: warehouseNumber,
        warehouseAddress: warehouseAddress,
        warehouseCity: warehouseCity,
        warehouseState: warehouseState,
        warehouseZip: warehouseZip,
        warehouseMarket: selectedMarket,
        warehouseType: warehouseType,
        warehouseDescription: warehouseDescription,
    };
    // console.log("data", data);

    await axios(
      {
      method: "post",
      url: `/adminstration/createwarehouselocations/`,
      data: data,
      headers: headers
    }
      )
      .then(function (response) {
        // console.log("response", response.data);
        props.handleMessageBarOpen(response.data.message, "success");
        props.toggleViewCreateWarehouseLocation();
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        props.handleMessageBarOpen(error.response.data.detail, "error");
      })
  };
  //!SECTION


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      {/* <Stack direction="column" alignItems="center" spacing={2}> */}
        <Typography component="h1" variant="h5">
          Create a Warehouse Location
        </Typography>

        <Box
          component="form"
          onSubmit={handleCreateWarehouse}
          noValidate
          overflow="auto"
          sx={{ mt: 1 }}
        >
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          {userData.userRole === 10 &&
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignAccount"
            label="Account"
            value={selectedAccount}
            onChange={handleAccountSelect}
            type="number"
            id="assignAccount"
            helperText="Select an Account"
          >
            {props.accountList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          }

          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignMarket"
            label="Market"
            value={selectedMarket}
            onChange={handleMarketSelect}
            type="number"
            id="assignMarket"
            helperText="Select an Market"
          >
            {props.marketList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.id}
                value={option.id}
              >
                {option.id}
              </MenuItem>
            ))}
          </TextField>


          <TextField
            error = {validFields.warehouseNumber}
            helperText={helperText.warehouseNumber}
            margin="normal"
            required
            fullWidth
            name="warehouseNumber"
            label="ex. 802718"
            type="text"
            id="warehouseNumber"
            onChange={(e) => formValidation(e)}
          />

          <TextField
            error = {validFields.warehouseLocationName}
            helperText={helperText.warehouseLocationName}
            margin="normal"
            required
            fullWidth
            name="warehouseLocationName"
            label="ex. Marysville Warehouse Room 1"
            type="text"
            id="warehouseLocationName"
            onChange={(e) => formValidation(e)}
          />

          <TextField
            error = {validFields.warehouseAddress}
            helperText={helperText.warehouseAddress}
            margin="normal"
            required
            fullWidth
            name="warehouseAddress"
            label="ex. 123 Main St."
            type="text"
            id="warehouseAddress"
            onChange={(e) => formValidation(e)}
          />


          <TextField
            error = {validFields.warehouseCity}
            helperText={helperText.warehouseCity}
            margin="normal"
            required
            fullWidth
            name="warehouseCity"
            label="ex. Marysville"
            type="text"
            id="warehouseCity"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.warehouseState}
            helperText={helperText.warehouseState}
            margin="normal"
            required
            fullWidth
            name="warehouseState"
            label="ex. OH"
            type="text"
            id="warehouseState"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.warehouseZip}
            helperText={helperText.warehouseZip}
            margin="normal"
            required
            fullWidth
            name="warehouseZip"
            label="ex. 43040"
            type="text"
            id="warehouseZip"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.warehouseType}
            helperText={helperText.warehouseType}
            margin="normal"
            fullWidth
            name="warehouseType"
            label="ex. Warehouse, FSL, Hospital, Storage Room, Office, etc."
            type="text"
            id="warehouseType"
            onChange={(e) => formValidation(e)}
          />

          <TextField
            error = {validFields.warehouseDescription}
            helperText={helperText.warehouseDescription}
            margin="normal"
            fullWidth
            name="warehouseDescription"
            label="ex. This is the warehouse for Marysville Hospital."
            type="text"
            id="warehouseDescription"
            onChange={(e) => formValidation(e)}
          />
          

          <Button
            disabled={buttonDisable}
            align="center"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Warehouse Location
          </Button>
          
          {/* <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleEmailCheckbox} />}
            label="Send Mobile User Notification Email"
          /> */}
        </Box>
      {/* </Stack> */}
    </Grid>
  );
}