import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { dataGridStyles } from './dataGridStyles';

import { 
  DataGridPro,
   } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';


export default function AdminGrid(props) {

  //if no props filter model is passed, set it to empty
  const [selectedModel, setSelectedModel ] = React.useState();
  const  GridData  = {
    columns: props.gridColumns, 
    rows: props.gridData
    };

  return (

    // <div style={{ 
    //   height: "100%", 
    //   width: "100%",
    //   }}>
    <Grid container
      sx={{ 
        display: 'flex',
        width: "100%",
        height: "100%",
        border: 0,
        justifyContent: 'flex-start',
        direction: 'row',
        overflow: 'hidden',

      }}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{alignContent: 'center', justifyContent:'center', border: 0, pt:1}}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h6" gutterBottom center >{props.gridTitle}</Typography>
          </Box>
        </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{alignContent: 'center', border: 0}}>
      <DataGridPro
        sx={dataGridStyles.root}
        hideFooter

      // onSelectionModelChange={(newSelectionModel) => {
      //   setSelectedModel(newSelectionModel)
        // props.handleMarketFileNames(newSelectionModel)
      // }}
      // selectedModel={selectedModel}
      {...GridData} 
      />
    </Grid>
    </Grid>

  );
};