import React from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext.js';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageSnackBar from './MessageSnackBar.js';

import AdminGrid from './AdminGrid.js';
import { 
    Button, 
    Grid,
    Paper 
  } from '@mui/material';


const AdminMetrics = (props) => {
    //SECTION USER CONTEXT and HEADERS
    const userData = useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
    // !SECTION

    //SECTION Message Bar Controls
      const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
      const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
      const [ messageSeverity, setMessageSeverity ] = React.useState('success');
      const handleMessageBarClosing = () => {
        setMessageBarOpen(false);
      };
      //!SECTION

    //SECTION Component Controls
    const itemMessage = 'Admin Metrics';
    const toggleItems = () => {
      if (viewItems){
          setViewItems(false);
          setViewItemsMessage(`${itemMessage}`);
          // console.log("user data", userData.name)
      }else{
          // getWarehouseList(userData.email)
          setViewItems(true);
          setViewItemsMessage(`Hide ${itemMessage}`);
      };
  } ;
    const [ viewItems, setViewItems ] = React.useState(false);
    const [ viewItemsMessage, setViewItemsMessage ] = React.useState(`View ${itemMessage}`);

    //!SECTION

  //SECTION - DataGrid Admin Metrics Controls
    const [ gridData, setGridData ] = React.useState([]);

    const gridColumns =  [
      // {field: 'id', hidden: true},
      {field: 'id', headerName: '',  editable: false, headerAlign: 'center', align: 'center',  type:'number', width: 150},
      {field: 'platform', headerName: 'Platform', headerAlign: 'center', align: 'center',  editable: false, flex: 1},
      {field: 'mobile', headerName: 'Mobile',  headerAlign: 'center', align: 'center', editable: false, flex: 1},
      ]

    //!SECTION

  //SECTION - DataGrid Current Metrics Controls
    const [ gridCurrentData, setGridCurrentData ] = React.useState([]);
    const [ gridDiscrepancyData, setGridDiscrepancyData ] = React.useState([]);
    // // const [ visibleColumns, setVisibleColumns ] = React.useState([]);

    const gridCurrentColumns =  [
      {field: 'id', headerName: '',  editable: false, headerAlign: 'center', align: 'center',  type:'number', width: 250},
      {field: 'count', headerName: 'Count', headerAlign: 'center', align: 'center',  editable: false, flex: 1},
      ]

    //!SECTION


    //SECTION - Data Fetching 

    React.useEffect(() => {
        getAdminStats(userData.email);
        getCurrentStats(userData.email);
    }, []);

    const getAdminStats = async (user) => {
        var parameters = { accountcode: userData.accountCode}; 
        await axios
        .get(
            `/Metrics/getAdminMetrics`,
            { headers: headers, params: parameters }
            )
            .then(function async(response) {
            setGridData(response.data.message)
            })
            .catch(function (error) {
            if (error.response.status === 401) {
                alert ("Your session has expired. Please login again.");
    
            }
            if (error.response.status !== 401) {
                setMessageBarMessage(`${error.response.data.detail}  Something went wrong. Contact info@edonconnect.tech for help.`);
                setMessageBarOpen(true);
                setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
            }
            })
        };

    const getCurrentStats = async (user) => {
        var parameters = { accountcode: userData.accountCode}; 
        await axios
        .get(
            `/Metrics/currentMetrics`,
            { headers: headers, params: parameters }
            )
            .then(function async(response) {
              console.log("current metrics", response.data.message.slice(6, 13))
            setGridCurrentData(response.data.message.slice(0, 6))
            setGridDiscrepancyData(response.data.message.slice(6, 13))
            })
            .catch(function (error) {
            if (error.response.status === 401) {
                alert ("Your session has expired. Please login again.");
    
            }
            if (error.response.status !== 401) {
                setMessageBarMessage(`${error.response.data.detail}  Something went wrong. Contact info@edonconnect.tech for help.`);
                setMessageBarOpen(true);
                setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
            }
            })
        };


  return (  

      <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ alignContent: "center", border: 0 }}
      >
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
        >
          <Button 
          onClick={toggleItems}
          startIcon={<ManageAccountsIcon size="large" />}
          >
            {" "}
            {viewItemsMessage}
          </Button>

          {/* Component Items */}
          {viewItems &&
          <>
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} /> 
          
          {/* <Grid container direction="row" marginTop={5} marginBottom={5} sx={{justifyContent: "space-between", border:1, borderStyle: 'dotted', borderRadius: '5px'  }}> */}
          <Grid
            container
            marginTop={5}
            sx={{
              justifyContent: "space-between",
              alignContent: "flex-start",
              p: 1,
              border: 1,
              borderStyle: "dotted",
              borderRadius: "5px",
              overflow: 'hidden'
            }}
          >
          <Grid
          item
          xs={3}
          md={3}
          lg={3}
          sx={{ 
            display: 'flex',
            alignContent: 'flex-start',
            alignItems: 'center',
            border: 1,
            borderStyle: "dotted",
            borderRadius: "5px",
          }}
          >

            <AdminGrid gridColumns={gridColumns} gridData={gridData} gridTitle={"Current Users"} />

          </Grid>      

          <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ 
            display: 'flex',
            alignContent: 'flex-start',
            alignItems: 'center',
            border: 1,
            borderStyle: "dotted",
            borderRadius: "5px",
            }}
          >
            <AdminGrid gridColumns={gridCurrentColumns} gridData={gridCurrentData} gridTitle={"Locations and Shipping"} />
          </Grid>   

          <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{  
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            border: 1,
            borderStyle: "dotted",
            borderRadius: "5px",

            }}
          >
            <AdminGrid gridColumns={gridCurrentColumns} gridData={gridDiscrepancyData} gridTitle={"Transactions and Discrepancies"} />
          </Grid>    
                    



          </Grid>
        </>
    }
        </Paper>
      </Grid>
  </>


  );
};
export default AdminMetrics;