import React, { useState } from 'react';
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

function MessageBell() {
  const [anchorEl, setAnchorEl] = useState(null);

  // Example messages
  const messages = [
    // { id: 1, text: 'You have no messages at this time.' },
    // { id: 2, text: 'New comment on your post.' },
    // { id: 3, text: 'Reminder: Meeting at 3 PM.' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'message-popover' : undefined;

  return (
    <>
      <IconButton
        aria-label="notifications"
        aria-describedby={id}
        onClick={handleClick}
      >
        <Badge badgeContent={messages.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant="h5" sx={{ p: 2 }}>
          Notifications
        </Typography>
        <List sx={{ width: 300 }}>
          {messages.length > 0 ? (
            messages.map((message) => (
              <ListItem key={message.id} divider>
                <ListItemText primary={message.text} />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" sx={{ p: 2 }}>
              No new notifications.
            </Typography>
          )}
        </List>
      </Popover>
    </>
  );
}

export default MessageBell;
